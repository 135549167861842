import React from "react"
import "./susfinteq.css"
import Footer from "../../components/footer"
import WorkHeader from "../../components/workHeader"
import susfinteq from "../../../static/work_headers/susfinteq.png"
import Dict from "../../langs/dict"
import WorkAbout from "../../components/workAbout"
import Perk from "../../components/perks"
import susfinteq2 from "../../../static/works_perks/susfinteq_2.png"
import susfinteq1 from "../../../static/works_perks/susfinteq_1.png"
import Contact from "../../components/contact"
import logo from "../../../static/logos/susfinteq_logo.png"
import Layout from "../../components/layoutWorks"

export default () => {
  const lang = "en"
  const dict = new Dict(lang)
  return (
    <Layout
      seoTitle="UI/UX - Susfinteq"
      seoDescription="A startup with a bold vision needs the strong identity that comes with it. To create it, we worked closely with the founders to infuse elements of technology, finance and sustainability."
    >
      <div id="works-susfinteq-background">
        <WorkHeader
          title={dict.getTrans("work_susfinteq_title")}
          subTitle={dict.getTrans("work_susfinteq_subtitle")}
          pic={susfinteq}
          navLang={lang}
          hl="#22D8A3"
          pageUrl="/works/susfinteq"
        />
      </div>
      <WorkAbout
        title={dict.getTrans("susfinteq_about_title")}
        p1={dict.getTrans("susfinteq_about_p1")}
        p2={dict.getTrans("susfinteq_about_p2")}
        logo={logo}
      />
      <div id="works-susfinteq-perks-holder">
        <Perk
          title={dict.getTrans("susfinteq_perk1_title")}
          image={susfinteq1}
          text={dict.getTrans("susfinteq_perk1_text")}
        />
        <Perk
          title={dict.getTrans("susfinteq_perk2_title")}
          image={susfinteq2}
          text={dict.getTrans("susfinteq_perk2_text")}
        />
      </div>
      <div id="works-tft-contact">
        <Contact lang="en" />
      </div>
      <Footer lang={lang} />
    </Layout>
  )
}
